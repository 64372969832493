<template>
    <r-e-dialog title="核验码核验" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel"
                :footerBtnName="['取消','核验信息']">
        <el-form ref="formPublish" label-width="120px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="房源核验码">
                    <el-input v-model="formPublish.housingCode" style="width:195px;"/>
                </el-form-item>
                <el-form-item label="经纪人姓名">
                    <el-input v-model="formPublish.brokerName" style="width: 195px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="经纪人身份证">
                    <el-input v-model="formPublish.brokerID" style="width: 195px;"/>
                </el-form-item>
                <el-form-item label="中介机构名称">
                    <el-input v-model="formPublish.intermediaryName" style="width: 195px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {getHousingCodeInfo} from "@/api/house-resource";
import {MessageError} from "@custom/message";

export default {
    name: "dialog-verification",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                housingCode: null,
                brokerName: null,
                brokerID: null,
                intermediaryName: null,
                uuid: null,
            },
            rules: {},
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        }
    },
    components: {},
    methods: {
        // 打开核验码核验弹框方法
        openDialog() {
            //获取房间uuid
            const {apartmentUuid: uuid} = this.currentHouse;
            //保存uuid
            this.formPublish.uuid = uuid;
            //打开弹窗
            this.dialogVisible = true;
        },
        // 点击确认按钮事件
        clickSubmit() {
            let that = this;
            //表单校验
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    //解构数据
                    const {housingCode, brokerName, brokerID, intermediaryName, uuid} = that.formPublish;
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    // 调用接口核验码核验
                    getHousingCodeInfo({housingCode, brokerName, brokerID, intermediaryName, uuid}).then(res => {
                        //解构返回数据
                        const {msg} = res;
                        //字符串转对象后解构返回参数
                        const {result} = JSON.parse(msg);
                        //解构返回状态码
                        let {statusCode, statusMsg} = result;
                        //转换code为数字
                        statusCode = Number(statusCode);
                        //判断statusCode是否大于0，大于0表示报错
                        if (statusCode > 0) {
                            MessageError(statusMsg, 15000);
                        } else {
                            // 解构返回数据
                            let {
                                businessStatus, housingCode, businessNo, street, districtName, buildingArea,
                                mortgaged, limited, onRent, seize, securityHousing, decrepitHouse, leaseRecord,
                            } = result;
                            //字段值类型转换
                            decrepitHouse = decrepitHouse !== null && decrepitHouse !== '' ? Number(decrepitHouse) : null;
                            mortgaged = mortgaged !== null && mortgaged !== '' ? Number(mortgaged) : null;
                            limited = limited !== null && limited !== '' ? Number(limited) : null;
                            onRent = onRent !== null && onRent !== '' ? Number(onRent) : null;
                            seize = seize !== null && seize !== '' ? Number(seize) : null;
                            securityHousing = securityHousing !== null && securityHousing !== '' ? Number(securityHousing) : null;
                            leaseRecord = leaseRecord !== null && leaseRecord !== '' ? Number(leaseRecord) : null;

                            //根据值的数据，获取实际展示数据
                            const mortgagedName = mortgaged !== null ? (mortgaged === 0 ? "无抵押" : "有抵押") : null;
                            const limitedName = limited !== null ? (limited === 0 ? "无限制" : "有限制") : null;
                            const onRentName = onRent !== null ? (onRent === 0 ? "未租赁" : "已租赁") : null;
                            const seizedName = seize !== null ? (seize === 0 ? "未查封" : "查封") : null;
                            const securityHousingName = securityHousing !== null ? (securityHousing === 0 ? "否" : "是") : null;
                            const leaseRecordName = leaseRecord !== null ? (leaseRecord === 0 ? "否" : "是") : null;
                            let decrepitHouseName = null;
                            if (decrepitHouse !== null) {
                                switch (decrepitHouse) {
                                    case 0:
                                        decrepitHouseName = "否";
                                        break;
                                    case 1:
                                        decrepitHouseName = "A级 无危险";
                                        break;
                                    case 2:
                                        decrepitHouseName = "B级 无危险";
                                        break;
                                    case 3:
                                        decrepitHouseName = "C级 潜在危险";
                                        break;
                                    case 4:
                                        decrepitHouseName = "D级 危险";
                                        break;
                                }
                            }
                            //创建动态元素
                            const h = that.$createElement;
                            let arr = [];
                            arr.push(h('div', null, ' 申请状态:' + (businessStatus || "无") + ''));
                            arr.push(h('div', null, ' 房源核验码:' + (housingCode || "无") + ''));
                            arr.push(h('div', null, ' 核验业务编号:' + (businessNo || "无") + ''));
                            arr.push(h('div', null, ' 街道:' + (street || "无") + ''));
                            arr.push(h('div', null, ' 房屋所在城区:' + (districtName || "无") + ''));
                            arr.push(h('div', null, ' 建筑面积:' + (buildingArea || "无") + ''));
                            arr.push(h('div', null, ' 是否有抵押:' + (mortgagedName || "无") + ''));
                            arr.push(h('div', null, ' 是否有限制:' + (limitedName || "无") + ''));
                            arr.push(h('div', null, ' 是否在租 :' + (onRentName || "无") + ''));
                            arr.push(h('div', null, ' 是否查封:' + (seizedName || "无") + ''));
                            arr.push(h('div', null, ' 是否保障房:' + (securityHousingName || "无") + ''));
                            arr.push(h('div', null, ' 是否危房 :' + (decrepitHouseName || "无") + ''));
                            arr.push(h('div', null, ' 是否租赁备案:' + (leaseRecordName || "无") + ''));
                            //提示
                            this.$confirm(h('div', null, arr), '提示', {
                                confirmButtonText: '确认',
                                cancelButtonText: '取消',
                                type: ''
                            }).then(() => {
                                //调用取消按钮事件，关闭弹框
                                that.clickCancel();
                            }).catch(() => {
                                //调用取消按钮事件，关闭弹框
                                that.clickCancel();
                            });

                        }
                    }).finally(() => loading.close());
                }
            });
        },
        // 点击取消按钮事件
        clickCancel() {
          // alert('取消')
            this.formPublish = {
                housingCode: null, brokerName: null, brokerID: null, intermediaryName: null, uuid: null
            };
            this.dialogVisible = false;
        },
    }
}
</script>

<style scoped>

</style>